import React, { useState } from "react";

import "./botmode.css";
import LauncherIcon from "../../../assets/svgs/logo-no-bg.svg";
import LauncherIconActive from "../../../assets/images/close-icon.png";

const BotModeIndex = ({ launcherPosition }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  }

  const classList = [
    "btm-launcher",
    isOpen ? "opened" : "",
    launcherPosition ? "launcher-center" : "",
  ];

  return (
    <>
      <div id="btm-launcher">
        <div
          onClick={() => handleClick()}
          className={classList.join(" ")}
        >
          {/* <MessageCount count={this.props.newMessagesCount} isOpen={isOpen} /> */}
          <img
            alt="launcherIconopen"
            src={LauncherIconActive}
            className={`btm-open-icon ${
              launcherPosition ? "launcher-center" : ""
            }`}
          />
          <img
            src={LauncherIcon}
            alt="launcherIconclose"
            className={`btm-closed-icon ${
              launcherPosition ? "launcher-center" : ""
            }`}
          />
        </div>
        {/* <ChatWindow
          messageList={this.props.messageList}
          onUserInputSubmit={this.props.onMessageWasSent}
          onFilesSelected={this.props.onFilesSelected}
          agentProfile={this.props.agentProfile}
          isOpen={isOpen}
          onClose={this.handleClick.bind(this)}
          showEmoji={this.props.showEmoji}
          launcherPosition={launcherPosition}
        /> */}
      </div>
    </>
  );
};

export default BotModeIndex;
